import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  extendTheme,
  CSSReset, ColorModeScript,
} from '@chakra-ui/react';
import { GoogleAuthProvider} from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import Homepage from './Components/Homepage';
import Login from './Components/Login';
import Jobs from './Components/Jobs';
import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";

import { BrowserRouter as Router, Route, Routes, useNavigate, Link, useRoutes } from 'react-router-dom';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const theme = extendTheme({
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
  styles: {
    global: (props) => ({
      "input::placeholder, textarea::placeholder": {
        color: "#3C7684",
      },
    }),
  }
});

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export let auth = getAuth(app);
export let db = getFirestore(app);
export let provider = new GoogleAuthProvider();
function App() {

  const [user] = useState();

  // Create UserContext here
  const UserContext = React.createContext();

  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <CSSReset />
          <Router>
            <UserContext.Provider value={user}>
              <Routes>
                <Route path="/" element={<Homepage user={user} />} />
                <Route path="/login" element={<Login auth={auth} provider={provider} db={db}  />} />
                <Route path="/jobs" element={<Jobs user={user} db={db} />} />
              </Routes>
            </UserContext.Provider>
          </Router>
    </ChakraProvider>
  );
}
export const UserContext = React.createContext();
export default App;
