import React, { useState, useEffect } from 'react';
import Logo from '../assets/img/logo-no-background.png';
import { auth, provider } from '../App';
import {
  Text,
  Flex,
  Button,
  Center,
  Box,
  Image,
  Modal,
  ModalOverlay,
  ScaleFade,
  Spinner
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import job from '../assets/img/job4.png';
import { Footer } from "./Footer";
import { signInWithPopup, onAuthStateChanged } from "firebase/auth";
import { Google } from 'react-bootstrap-icons';
let Login = () => {
  let [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  let handleGoogleSignIn = async () => {
    try {
      setIsLoading(true);
      // Start a sign in process for an unauthenticated user.
      const result = await signInWithPopup(auth, provider);
      if (result.user) {
        setIsLoading(false);
        navigate('/jobs');
      } else {
        setIsLoading(false);
        navigate('/login');
      }
    } catch (error) {
      setIsLoading(false);
      navigate('/login');
    }
  };

  useEffect(() => {
    setIsLoading(true);
    onAuthStateChanged(auth, user => {
      if (user) {
        setIsLoading(false);
        navigate('/jobs');
      } else {
        setIsLoading(false);
        navigate('/login');
      }
    });
  }, [navigate]);

  return (
    isLoading ?
      <Modal isOpen={true} isCentered>
        <ModalOverlay
          css={{
            background: '#052529',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ScaleFade initialScale={0.9} in={true}>
            <Flex direction="column" align="center" justify="center" height="100vh" width="100vw">
              <Spinner size="xl" color="#81e6d9" />
              <Center mt={4}>We're almost there</Center>
            </Flex>

          </ScaleFade>
        </ModalOverlay>
      </Modal>
      :
      <>
        <Box
          sx={{
            backgroundImage: `url('${job}')`,
            backgroundPosition: 'center',
            _important: { backgroundImage: true, backgroundPosition: true },
          }}
          bgSize="cover"
          bgRepeat="no-repeat"
          alt='job interview'
          width={'100%'}
        >
          <Flex h="100vh" alignItems="center" direction="column" justifyContent="space-around" flexWrap="wrap">
            <Flex h="77vh" alignItems="center" justifyContent="center">
              <Flex
                flexDirection="column"
                bg='none'
                p={12}
                borderRadius={8}
                boxShadow='lg'
                zIndex={1}
                borderColor="#81e6d9"
                borderWidth="1px"
                borderstyle="solid"
              >
                <Box mb={6}>
                  <Center zIndex={1}>
                    <Link to='/'>
                      <Image src={Logo} alt="Logo of Job Hunt UK" boxSize='50px' />
                    </Link>
                  </Center>
                  <Center mt={3}> Welcome to Job Hunt </Center>
                </Box>
                <Button className="g-recaptcha" data-sitekey="6LfQDWYmAAAAAJF9tKMOEAsYn70U1VEWdUaxnrtr" colorScheme="red" mb={2} leftIcon={<Google />} onClick={handleGoogleSignIn}>
                  Sign in with Google
                </Button>
              </Flex>
            </Flex>
            <Footer />
          </Flex>
        </Box>
      </>

  );
};

export default Login;

