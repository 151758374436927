import { React } from "react";
import { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { Spinner, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Text, Collapse, Image, Box, Flex, Button, useColorMode, useColorModeValue, ButtonGroup, HStack, IconButton, useBreakpointValue, Container } from "@chakra-ui/react";
import { FaDoorOpen, FaTrash } from "react-icons/fa";
import { FiMenu } from 'react-icons/fi';
import Logo from '../assets/img/logo-no-background.png';
import { deleteDoc, doc } from "firebase/firestore";
import { db, auth } from '../App';
import UserMenu from "./UserMenu";
import { BrowserRouter as Router, Route, Routes, useNavigate, Link} from 'react-router-dom';


function Header() {
  let navigate = useNavigate();
  let [setJobs] = useState([]);
  let [setFilteredJobs] = useState([]);
  let { isOpen: isClearJobsModalOpen, onOpen: onClearJobsModalOpen, onClose: onClearJobsModalClose } = useDisclosure();
  let toast = useToast();
  let [user, loading, error] = useAuthState(auth);
  let isDesktop = useBreakpointValue({ base: false, lg: true })
  let [isOpen, setIsOpen] = useState(false);

  let clearUserJobs = async () => {
    let user = auth.currentUser;
    if (user) {
      let docRef = doc(db, 'searchedJobs', user.uid);
      await deleteDoc(docRef);
      toast({
        title: "Job data successfully cleared",
        status: "success",
        position: "top",
        duration: 5000,
        isClosable: true,
      });
      window.location.reload(); // Reload the page

    }
    onClearJobsModalClose();
  };

  let toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  let signOutUser = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (err) {
      console.error("Sign out error", err);
    }
  };

  if (loading) {
    return <Modal isOpen={loading} isCentered>
      <ModalOverlay
        css={{
          background: 'transparent',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner size="xl" color="#81e6d9" />
      </ModalOverlay>
    </Modal>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Box as="nav" role="navigation" p={4}>
      <Flex justify="space-between" align="center" wrap="wrap">

        <Button
          p={0}
          background={"none"}
          _hover={{ background: "none" }}
        >
          <Box p={4}>
            <Image src={Logo} alt="Job Hunt UK Logo" boxSize='30px' top={"./"} />
          </Box>
        </Button>

       {user ? <UserMenu /> : null}
        <IconButton
          variant="ghost"
          icon={<FiMenu fontSize="1.25rem" />}
          aria-label="Open Menu"
          
          onClick={toggleMenu}
          colorScheme="whiteAlpha"
          color="white"
        />
      </Flex>
      <Collapse in={isOpen}>
        <Flex justify="flex-end" flex="1" mt={4} mr={5}>
          <HStack spacing="3">
            <Button onClick={onClearJobsModalOpen}
              colorScheme="red"
              px={4}
              leftIcon={<FaTrash />}
            >
              Clear Job Data
            </Button>
            <Modal isOpen={isClearJobsModalOpen} onClose={onClearJobsModalClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Confirm Action</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  Are you sure you want to clear job data?
                </ModalBody>
                <ModalFooter>
                  <Button variant="ghost" mr={3} onClick={onClearJobsModalClose}>
                    Cancel
                  </Button>
                  <Button colorScheme="red" onClick={clearUserJobs}>
                    Clear
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
            <Button onClick={signOutUser}
            as={Link}
            to={"./"}
              colorScheme="teal"
              px={4}
              leftIcon={<FaDoorOpen />}
            >
              Sign Out
            </Button>
          </HStack>
        </Flex>
      </Collapse>
    </Box>
  );

};



export default Header;
