import { useState, useEffect, useCallback } from "react";
import {
    Box,
    Button,
    Flex,
    Text,
    Select,
    IconButton
} from "@chakra-ui/react";
import { RiFilterLine, RiFilterFill } from "react-icons/ri";
import { CloseIcon } from "@chakra-ui/icons";

let Filters = ({ jobs, handleFiltersChange, clearFilters }) => {
    let [isFiltersOpen, setIsFiltersOpen] = useState(false);
    let [selectedJobTitles, setSelectedJobTitles] = useState([]);
    let [selectedLocations, setSelectedLocations] = useState([]);
    let [shouldApplyFilters, setShouldApplyFilters] = useState(false);
    let [jobTitleValue, setJobTitleValue] = useState("");
    let [locationValue, setLocationValue] = useState("");
    let [selectedSalary, setSelectedSalary] = useState([]);
    let [selectedEducation, setSelectedEducation] = useState([]);
    let [salaryValue, setSalaryValue] = useState("");
    let [educationValue, setEducationValue] = useState("");
    const educationKeyMap = {
        "Associate's Degree": 'associates_degree',
        "Bachelor's Degree": 'bachelors_degree',
        "Degree Mentioned": 'degree_mentioned',
        "Degree Preferred": 'degree_preferred',
        "High School": 'high_school',
        "Postgraduate Degree": 'postgraduate_degree',
        "Professional Certification": 'professional_certification',
        "Professional Certification Mentioned": 'professional_certification_mentioned',
    };



    let toggleFilters = () => {
        setIsFiltersOpen(!isFiltersOpen);
    };

    // Full list of unique job titles and locations
    let allUniqueJobTitles = [...new Set(jobs.map((job) => job.job_job_title))];
    let allUniqueLocations = [...new Set(jobs.map((job) => job.job_city || 'Unknown'))];

    // Filtered list based on selected options of the other dropdown
    let uniqueJobTitles = selectedLocations.length > 0
        ? allUniqueJobTitles.filter(jobTitle => jobs.some(job => job.job_job_title === jobTitle && selectedLocations.includes(job.job_city || 'Unknown')))
        : allUniqueJobTitles;

    let uniqueLocations = selectedJobTitles.length > 0
        ? allUniqueLocations.filter(location => jobs.some(job => job.job_city === location && selectedJobTitles.includes(job.job_job_title)))
        : allUniqueLocations;

    let handleLocationClick = (location) => {
        if (location === "") {
            handleLocationClear();
            return;
        }
        setSelectedLocations([location]);
        handleFiltersChange({ jobTitles: selectedJobTitles, locations: [location], });
    };


    let handleJobTitleClick = (jobTitle) => {
        if (jobTitle === "") {
            handleJobTitleClear();
            return;
        }
        setSelectedJobTitles([jobTitle]);
        handleFiltersChange({ jobTitles: [jobTitle], locations: selectedLocations });
    };
    let handleEducationChange = (education) => {
        handleEducationClear();
        setSelectedEducation((prevSelectedEducation) => {
            let newEducation = prevSelectedEducation.includes(education)
                ? prevSelectedEducation.filter((edu) => edu !== education)
                : [...prevSelectedEducation, education];

            handleFiltersChange({
                jobTitles: selectedJobTitles,
                locations: selectedLocations,
                salary: selectedSalary,
                education: newEducation
            });

            return newEducation;
        });
    };
    let handleSalaryChange = (salaryRangeString) => {
        handleSalaryClear();
        let salary = salaryRangeString;

        setSelectedSalary((prevSelectedSalary) => {
            let newSalary = prevSelectedSalary.includes(salary)
                ? prevSelectedSalary.filter((sal) => sal !== salary)
                : [...prevSelectedSalary, salary];

            handleFiltersChange({
                jobTitles: selectedJobTitles,
                locations: selectedLocations,
                salary: newSalary,
                education: selectedEducation
            });

            return newSalary;
        });
    };

    let handleJobTitleClear = () => {
        setSelectedJobTitles([]);
        setJobTitleValue("");
        handleFiltersChange({ jobTitles: [], locations: selectedLocations }); // clear the job title filter
    };

    let handleLocationClear = () => {
        setSelectedLocations([]);
        setLocationValue("");
        handleFiltersChange({ jobTitles: selectedJobTitles, locations: [] }); // clear the location filter
    };



    // Clearing salary and education filters
    let handleSalaryClear = () => {
        setSelectedSalary([]);
        setSalaryValue("");
        handleFiltersChange({
            jobTitles: selectedJobTitles,
            locations: selectedLocations,
            salary: [],
            education: selectedEducation
        });
    };

    let handleEducationClear = () => {
        setSelectedEducation([]);
        setEducationValue("");
        handleFiltersChange({
            jobTitles: selectedJobTitles,
            locations: selectedLocations,
            salary: selectedSalary,
            education: []
        });
    };
    let handleApplyFilters = useCallback(() => {
        if (!shouldApplyFilters) {
            return;
        }

        setTimeout(() => handleFiltersChange({
            jobTitles: selectedJobTitles,
            locations: selectedLocations,
            salary: selectedSalary,
            education: selectedEducation
        }), 0);
        setShouldApplyFilters(false);
    }, [shouldApplyFilters, selectedJobTitles, selectedLocations, selectedSalary, selectedEducation, handleFiltersChange]);

    useEffect(() => {
        handleApplyFilters();
    }, [handleApplyFilters]);

    function reducer(state, action) {
        switch (action.type) {
            case 'setEducation':
                return {
                    ...state,
                    selectedEducation: action.payload,
                };
            case 'setSalary':
                return {
                    ...state,
                    selectedSalary: action.payload,
                };
            // ... other action handlers
            default:
                throw new Error();
        }
    }

    // Create an array to store the salary ranges
    let salaryRanges = [];
    for (let i = 20000; i <= 100000; i += 10000) {
        salaryRanges.push({ min: i, max: i + 10000 - 1 });
    }

    // Add the range for salaries above 100,000
    salaryRanges.push({ min: 100001, max: Infinity });

    // Create an array to store the count of jobs in each range
    let jobCounts = salaryRanges.map(range => {
        return jobs.filter(
            job => job.job_min_salary >= range.min && job.job_max_salary <= range.max
        ).length;
    });



    return (
        <>
            <Flex alignItems="center" justifyContent="space-between">
                <Button
                    rightIcon={isFiltersOpen ? <RiFilterFill /> : <RiFilterLine />}
                    onClick={toggleFilters}
                    aria-label={isFiltersOpen ? 'Collapse filters' : 'Expand filters'}
                    colorScheme="teal"
                    px={4}
                    mb={2}
                >
                    Filters
                </Button>
            </Flex>

            {isFiltersOpen && (
                <Flex direction={["column", "column", "column"]} wrap="wrap">
                    <Box mb={4}>
                        <Text textAlign="left" color="#81e6d9" fontWeight="bold">Job Title</Text>
                        <Flex alignItems="center">
                            <Select w="200px" value={jobTitleValue} placeholder="Select Job Title" onChange={e => { handleJobTitleClick(e.target.value); setJobTitleValue(e.target.value); }}>
                                {uniqueJobTitles.map((jobTitle) => {
                                    let jobCount = jobs.filter(
                                        (job) =>
                                            job.job_job_title && jobTitle && job.job_job_title.toLowerCase() === jobTitle.toLowerCase() &&
                                            (selectedLocations.length === 0 || selectedLocations.includes(job.job_city || 'Unknown'))
                                    ).length;
                                    return (
                                        <option key={jobTitle} value={jobTitle}>
                                            {`${jobTitle} (${jobCount})`}
                                        </option>
                                    );
                                })}
                            </Select>
                            {selectedJobTitles.length > 0 && (
                                <IconButton
                                    colorScheme="teal"
                                    aria-label="Clear job title filter"
                                    icon={<CloseIcon />}
                                    onClick={
                                        handleJobTitleClear
                                    }
                                    ml={2}
                                />
                            )}
                        </Flex>
                    </Box>

                    <Box mb={4}>
                        <Text color="#81e6d9" textAlign="left" fontWeight="bold">Location</Text>
                        <Flex alignItems="center">
                            <Select w="200px" value={locationValue} placeholder="Select Location" onChange={e => { handleLocationClick(e.target.value); setLocationValue(e.target.value); }}>
                                {uniqueLocations.map((location) => {
                                    let jobCount = jobs.filter(
                                        (job) =>
                                            job.job_city === location &&
                                            (selectedJobTitles.length === 0 || selectedJobTitles.includes(job.job_job_title))
                                    ).length;
                                    return (
                                        <option key={location} value={location}>
                                            {`${location} (${jobCount})`}
                                        </option>
                                    );
                                })}
                            </Select>
                            {selectedLocations.length > 0 && (
                                <IconButton
                                    colorScheme="teal"
                                    aria-label="Clear location filter"
                                    icon={<CloseIcon />}
                                    onClick={handleLocationClear}
                                    ml={2}
                                />
                            )}
                        </Flex>
                    </Box>


                    <Box mb={4}>
                        <Text textAlign="left" color="#81e6d9" fontWeight="bold">Salary</Text>
                        <Flex alignItems="center">
                            <Select
                                w="200px"
                                value={salaryValue}
                                placeholder="Select Salary"
                                onChange={e => {
                                    handleSalaryChange(e.target.value);
                                    setSalaryValue(e.target.value);
                                }}
                            >
                                {salaryRanges.map((range, i) => {
                                    let value, label;
                                    if (range.max === Infinity) {
                                        value = `100001-Infinity`;
                                        label = `Above 100,000`;
                                    } else {
                                        value = `${range.min}-${range.max}`;
                                        label = `${range.min.toLocaleString()} - ${range.max.toLocaleString()}`;
                                    }
                                    return <option value={value}>{`${label} (${jobCounts[i]})`}</option>
                                })}
                            </Select>


                            {selectedSalary.length > 0 && (
                                <IconButton
                                    colorScheme="teal"
                                    aria-label="Clear salary filter"
                                    icon={<CloseIcon />}
                                    onClick={handleSalaryClear}
                                    ml={2}
                                />
                            )}
                        </Flex>
                    </Box>

                    {/* Education Filter */}
                    <Box mb={4}>
                        <Text color="#81e6d9" textAlign="left" fontWeight="bold">Education</Text>
                        <Flex alignItems="center">
                            <Select w="200px" value={educationValue} placeholder="Select Education" onChange={e => { handleEducationChange(e.target.value); setEducationValue(e.target.value); }}>
                                {['Associates Degree', 'Bachelors Degree', 'Degree Mentioned', 'Degree Preferred', 'High School', 'Postgraduate Degree', 'Professional Certification', 'Professional Certification Mentioned'].map((education) => {
                                    let educationKey = educationKeyMap[education];
                                    let jobCount = jobs.filter(
                                        (job) =>
                                            job.job_required_education[educationKey] &&
                                            (selectedJobTitles.length === 0 || selectedJobTitles.includes(job.job_job_title)) &&
                                            (selectedLocations.length === 0 || selectedLocations.includes(job.job_city || 'Unknown'))
                                    ).length;
                                    return (
                                        <option key={education} value={education}>
                                            {`${education} (${jobCount})`}
                                        </option>
                                    );
                                })}
                            </Select>
                            {selectedEducation.length > 0 && (
                                <IconButton
                                    colorScheme="teal"
                                    aria-label="Clear education filter"
                                    icon={<CloseIcon />}
                                    onClick={handleEducationClear}
                                    ml={2}
                                />
                            )}
                        </Flex>
                    </Box>
                </Flex>
            )}
        </>
    );
};

export default Filters;
