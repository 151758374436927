import { Box, Text, Flex } from "@chakra-ui/react";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";

function QualityStars({ score }) {
  let wholeStars = Math.floor(score * 5);
  let halfStar = Math.round(score * 10) % 2;

  let stars = [...Array(wholeStars)].map((_, i) => (
    <Box as={FaStar} key={i} size="15px" color="#FFD700" />
  ));

  if (halfStar) {
    stars.push(
      <Box as={FaStarHalfAlt} key="half" size="15px" color="#FFD700" />
    );
  }

  return (
    <Flex align="center" flexDirection="row"> 
      <Text ml="2" fontSize="sm" color="white" display="flex" flexDirection="row">
        {stars}
      </Text>
    </Flex>
  );
}

export default QualityStars;
