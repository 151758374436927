import { Box,IconButton, Button, Image, Text, Flex,useMediaQuery  } from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { useState, useEffect } from 'react';
import imagePlaceholder from './Placeholder.png';
import { useToast } from "@chakra-ui/react";
import Confetti from 'react-dom-confetti';
import { auth, provider } from '../App';

// Initialize Firebase, if not done so already
// import { initializeApp } from "firebase/app";
// initializeApp(yourFirebaseConfig);


function UserMenu() {
  let [user] = useAuthState(auth);
  let [isOpen, setIsOpen] = useState(false); // Define your state variable here
  let [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  let [confetti, setConfetti] = useState(false);
  let toggleMenu = () => {
    setIsOpen(!isOpen);
  };
    let toast = useToast();
    let firstName = user?.displayName.split(' ')[0]; 


 function UserToast({ firstName }) {
  let [confetti, setConfetti] = useState(false);

  let toastConfetti = {
    angle: 90,
    spread: 360,
    startVelocity: 20,
    elementCount: 70,
    decay: 0.95,
    duration: 5000,
  };

  useEffect(() => {
    setConfetti(true);
    let timeout = setTimeout(() => setConfetti(false), 5000); // Stop confetti after 5 seconds
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <Confetti active={confetti} config={toastConfetti} />
    
    </>
  );
}

function userToast() {
  toast({
    duration: 3000,
    position: "bottom",
    isClosable: true,
    render: () => <UserToast firstName={firstName} />,
  });
}
  
  return (
    <Flex alignItems="center">
    <Button
     background={"none"}
      mr={4}
      p={7}
      onClick={userToast}
    >
      <Image
        borderRadius="full"
        boxSize="40px"
        src={user.photoURL || imagePlaceholder} 
        alt="User Avatar"
        mr={2}
       
      />
      {isLargerThan768 && (
        <Text ml={2} color="white">
          {`${user?.displayName}`}
        </Text>
      )}
    </Button>
  </Flex>
  );
}

export default UserMenu;
