import React, { useState, useEffect, useCallback } from 'react';
import { auth, provider,db } from '../App';
import { ArrowUpIcon } from '@chakra-ui/icons';
import { Link as ScrollLink, Events, animateScroll as scroll, scroller } from 'react-scroll';
import axios from 'axios';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import Filters from "./Filters";
import QualityStars from './Stars';
import imagePlaceholder from '../assets/img/logo-no-background.png';
import Logo from '../assets/img/logo-no-background.png';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Footer } from "./Footer";
import './jobs.css';
import Header from './Header';
import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
  Spinner,
  Stack,
  ChakraProvider,
  Box,
  Button,
  Heading,
  Text,
  Flex,
  Card,
  Image,
  ScaleFade,
  Textarea,
  Center,
  Grid, Input,
  theme, CircularProgress, useToast, List, ListItem, IconButton, CardBody, CardHeader
} from '@chakra-ui/react';
import {
  FaCoins,
  FaEnvelope,
  FaMapMarkerAlt,
  FaBuilding,
  FaLink,
  FaRegCheckCircle,
  FaStarHalfAlt,
  FaClipboard,
  FaLaptopCode,
  FaUserTie,
  FaExternalLinkAlt,
  FaClock,
  FaRegClock,
  FaChartBar,
  FaGraduationCap,
  FaStar,
  FaRegStar,
  FaRobot
} from "react-icons/fa";
import jobImage from '../assets/img/job6.jpg';


function Jobs() {
  let [gptInstructions, setGptInstructions] = useState("");
  let [isModalOpen, setIsModalOpen] = useState(false);
  let [selectedJobId, setSelectedJobId] = useState(null);
  let openModal = (job_id) => {
    setSelectedJobId(job_id !== selectedJobId ? job_id : null);
    setIsModalOpen(job_id !== selectedJobId);
  };
  let [loading, setLoading] = useState();
  let [gptLoadingEmail, setGptLoadingEmail] = useState(false);
  let [gptLoadingCover, setGptLoadingCover] = useState(false);
  let toast = useToast();
  let [jobs, setJobs] = useState([]);
  let [filteredJobs, setFilteredJobs] = useState([]);
  let [favoriteJobs, setFavoriteJobs] = useState([]);
  let [showFavorites, setShowFavorites] = useState(false);
  let [keyword, setKeyword] = useState('');
  let [filters, setFilters] = useState({ jobTitles: [], locations: [], salary: [], education: [] });
  let [userFavorites, setUserFavorites] = useState([]);
  let [isCollapsed, setIsCollapsed] = useState(false);
  let [isDescriptionCollapsed, setIsDescriptionCollapsed] = useState(false);
  let [coverLetter, setCoverLetter] = useState('');
  let [email, setEmail] = useState('');
  let formatDate = (dateString) => {
    let options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }
 
  let clearFilters = () => {
    setFilteredJobs(jobs);
    let fetchData = async () => {
      let user = auth.currentUser;
      if (user) {
        let docRef = doc(db, 'searchedJobs', user.uid);
        let docSnap = await getDoc(docRef);
        console.log(docSnap);
        if (docSnap.exists()) {
          let jobsData = docSnap.data().jobs;
          let userFavorites = docSnap.data().favorites;
          setUserFavorites(userFavorites);
          console.log('jobsData: ', jobsData);
          if (Array.isArray(jobsData)) {
            let distinctJobs = [];
            let employerNames = new Set();
            for (let job of jobsData) {
              if (!employerNames.has(job.employer_name)) {
                employerNames.add(job.employer_name);
                distinctJobs.push(job);
              }
            }
            distinctJobs.sort((a, b) => new Date(b.job_posted_date) - new Date(a.job_posted_date));
            setJobs(distinctJobs);
            setFilteredJobs(distinctJobs); // set filteredJobs here
          } else {
            console.error("jobsData is not an array");
          }
        }
      }
    };
    fetchData();
  };

  useEffect(() => {
    let fetchData = async () => {
      let user = auth.currentUser;
      if (user) {
        let docRef = doc(db, 'searchedJobs', user.uid);
        let docSnap = await getDoc(docRef);
        console.log(docSnap);
        if (docSnap.exists()) {
          let jobsData = docSnap.data().jobs;
          let userFavorites = docSnap.data().favorites;
          setUserFavorites(userFavorites);
          console.log('jobsData: ', jobsData);
          if (Array.isArray(jobsData)) {
            let distinctJobs = [];
            let employerNames = new Set();
            for (let job of jobsData) {
              if (!employerNames.has(job.employer_name)) {
                employerNames.add(job.employer_name);
                distinctJobs.push(job);
              }
            }
            distinctJobs.sort((a, b) => new Date(b.job_posted_date) - new Date(a.job_posted_date));
            setJobs(distinctJobs);
            setFilteredJobs(distinctJobs); // set filteredJobs here
          } else {
            console.error("jobsData is not an array");
          }
        }
      }
    };
    fetchData();
  }, []);


  useEffect(() => {
    let results = [...jobs];

    if (filters.jobTitles.length > 0 || filters.locations.length > 0 || filters.salary.length > 0 || filters.education.length > 0) {
      results = jobs.filter(job => {
        let jobTitleCheck = filters.jobTitles.length > 0 ? filters.jobTitles.includes(job.job_job_title) : true;

        let locationCheck = filters.locations.length > 0 ? filters.locations.includes(job.job_city || 'Unknown') : true;

        let salaryCheck = true;
        if (filters.salary.length > 0) {
          let minSalary = Number(job.job_min_salary); // Ensure salary is a number
          if (minSalary) { // If minSalary is not null or NaN
            salaryCheck = filters.salary.some(salaryRange => {
              let [lowerBound, upperBound] = salaryRange.split('-').map(Number);
              if (upperBound) {
                return minSalary >= lowerBound && minSalary <= upperBound;
              } else {
                return minSalary >= lowerBound;
              }
            });
          } else {
            // If salary is null, decide how you want to handle this
            // E.g. we could treat null salaries as not satisfying any salary filter
            salaryCheck = false;
          }
        }

        let educationKeyMap = {
          "Associate's Degree": 'associates_degree',
          "Bachelor's Degree": 'bachelors_degree',
          "Degree Mentioned": 'degree_mentioned',
          "Degree Preferred": 'degree_preferred',
          "High School": 'high_school',
          "Postgraduate Degree": 'postgraduate_degree',
          "Professional Certification": 'professional_certification',
          "Professional Certification Mentioned": 'professional_certification_mentioned',
        };

        // Then use this map in your filter
        let educationCheck = true;
        if (filters.education.length > 0) {
          let requiredEducation = job.job_required_education;
          educationCheck = filters.education.some(educationLevel => {
            let educationKey = educationKeyMap[educationLevel]; // convert label to key
            return requiredEducation[educationKey];
          });
        }

        return jobTitleCheck && locationCheck && salaryCheck && educationCheck;
      });
    }
    setFilteredJobs(results);
  }, [jobs, filters]);
  let searchJobs = async () => {
    setLoading(true);
    toast({
      title: "Processing your request.",
      status: "info",
      position: "top",
      duration: 6000,
      isClosable: true,
    });

    if (!keyword) {
      toast({
        title: `Please enter a keyword`,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
      return;
    }

    let options = {
      method: 'GET',
      url: 'https://jsearch.p.rapidapi.com/search',
      params: {
        query: `${keyword}`,
        page: '1',
        num_pages: '5'
      },
      headers: {
        'X-RapidAPI-Key': process.env.REACT_APP_X_RAPIDAPI_KEY,
        'X-RapidAPI-Host': process.env.REACT_APP_X_RAPIDAPI_HOST
      }
    };

    try {
      let response = await axios.request(options);
      let jobsArray = Array.isArray(response.data.data) ? response.data.data : [];
      let newJobs = jobsArray.filter(job => !jobs.some(existingJob => existingJob.job_publisher === job.job_publisher && existingJob.employer_name === job.employer_name && existingJob.job_title === job.job_title));

      let updatedJobs = newJobs.concat(jobs);
      setJobs(updatedJobs);
      setFilteredJobs(updatedJobs);
      setKeyword("");
      setLoading(false);
      toast({
        title: `Found ${newJobs.length} jobs!`,
        status: "success",
        position: "top",
        duration: 5000,
        isClosable: true,
      });
      let user = auth.currentUser;
      if (user) {
        let docRef = doc(db, 'searchedJobs', user.uid);
        let docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          let existingJobs = docSnap.data().jobs;
          let allJobs = existingJobs.concat(newJobs);
          await setDoc(docRef, { jobs: allJobs, favorites: docSnap.data().favorites || [] });
        } else {
          await setDoc(docRef, { jobs: newJobs, favorites: [] });
        }
      }

      let job_salary_predictions = [];



      console.log(job_salary_predictions);
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast({
        title: "Error",
        description: `An error occurred while searching for jobs. Please try again.`,
        status: "error",
        position: "top",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  let handleKeywordChange = (event) => {
    setKeyword(event.target.value);
  };

  let truncateText = (str, maxLen = 30) => {
    return (str && str.length > maxLen) ? `${str.substring(0, maxLen)}...` : str;
  };

  let handleFiltersChange = (newFilters, event) => {
    setFilters(prevFilters => ({ ...prevFilters, ...newFilters }));
  };




  let handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchJobs();
    }
  };


  let sendEmailToUser = async (jobLink, jobData) => {
    let auth = getAuth();
    let user = auth.currentUser;

    if (user) {
      let functions = getFunctions();
      let sendEmailFunction = httpsCallable(functions, 'sendEmailToUser');

      try {
        let response = await sendEmailFunction({
          email: user.email,
          jobLink,
          jobData,
        });

        if (response.data.result === 'Email sent successfully!') {
          toast({
            title: `Email sent successfully!`,
            status: 'success',
            position: 'top',
            duration: 5000,
            isClosable: true,
          });
        } else {
          throw new Error('Error sending email');
        }
      } catch (error) {
        console.error('Error sending email:', error);
        toast({
          title: `Error sending email. Please try again.`,
          status: 'error',
          position: 'top',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  function getCurrencyFormat(code) {
    let currencyFormats = {
      'GBP': { symbol: '£', placeAfter: false },
      'EUR': { symbol: '€', placeAfter: false },
      'USD': { symbol: '$', placeAfter: false },
      'RUB': { symbol: '₽', placeAfter: true },
      'INR': { symbol: '₹', placeAfter: false },
      'JPY': { symbol: '¥', placeAfter: false },
      'CNY': { symbol: '¥', placeAfter: false },
      'BRL': { symbol: 'R$', placeAfter: false },
      'CAD': { symbol: 'C$', placeAfter: false },
      'AUD': { symbol: 'A$', placeAfter: false },
      'AED': { symbol: 'د.إ', placeAfter: false },
      'ARS': { symbol: '$', placeAfter: false },
      'BDT': { symbol: '৳', placeAfter: false },
      'CHF': { symbol: 'CHF', placeAfter: true },
      'CLP': { symbol: '$', placeAfter: false },
      'COP': { symbol: '$', placeAfter: false },
      'CZK': { symbol: 'Kč', placeAfter: true },
      'DKK': { symbol: 'kr.', placeAfter: true },
      'EGP': { symbol: 'E£', placeAfter: false },
      'HKD': { symbol: 'HK$', placeAfter: false },
      'HRK': { symbol: 'kn', placeAfter: true },
      'HUF': { symbol: 'Ft', placeAfter: true },
      'IDR': { symbol: 'Rp', placeAfter: false },
      'ILS': { symbol: '₪', placeAfter: false },
      'KRW': { symbol: '₩', placeAfter: false },
      'KZT': { symbol: '₸', placeAfter: true },
      'MXN': { symbol: '$', placeAfter: false },
      'MYR': { symbol: 'RM', placeAfter: false },
      'NOK': { symbol: 'kr', placeAfter: true },
      'NZD': { symbol: 'NZ$', placeAfter: false },
      'PHP': { symbol: '₱', placeAfter: false },
      'PKR': { symbol: '₨', placeAfter: false },
      'PLN': { symbol: 'zł', placeAfter: true },
      'RON': { symbol: 'lei', placeAfter: true },
      'SEK': { symbol: 'kr', placeAfter: true },
      'SGD': { symbol: 'S$', placeAfter: false },
      'THB': { symbol: '฿', placeAfter: false },
      'TRY': { symbol: '₺', placeAfter: false },
      'TWD': { symbol: 'NT$', placeAfter: false },
      'UAH': { symbol: '₴', placeAfter: true },
      'VND': { symbol: '₫', placeAfter: false },
      'ZAR': { symbol: 'R', placeAfter: false },
    };

    return currencyFormats[code] || { symbol: code, placeAfter: false };
  }

  function toTitleCase(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  let JobDescription = ({ description }) => {
    return (
      description.split('\n').map((item, key) => {
        if (item.startsWith('•')) {
          return (
            <List styleType="disc" spacing={3} key={key}>
              <ListItem>{item.replace('• ', '')}</ListItem>
            </List>
          );
        } else if (item.trim() === '') {
          return <Box key={key} height="20px" />;
        } else {
          return <Text key={key} fontSize="md" my={2}>{item}</Text>;
        }
      })
    );
  };

  let [showScroll, setShowScroll] = useState(false);
  let checkScrollTop = useCallback(() => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  }, [showScroll]);
  useEffect(() => {
    Events.scrollEvent.register('begin', function (to, element) {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register('end', function (to, element) {
      console.log("end", arguments);
    });

    window.addEventListener('scroll', checkScrollTop);

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
      window.removeEventListener('scroll', checkScrollTop);
    }
  }, [checkScrollTop]);


  let scrollToTop = () => {
    scroll.scrollToTop();
  };
  async function handleFavoriteClick(jobId) {
    let user = auth.currentUser;
    if (user) {
      let docRef = doc(db, 'searchedJobs', user.uid);
      let docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let userFavorites = docSnap.data().favorites;

        if (userFavorites.includes(jobId)) {
          userFavorites = userFavorites.filter(id => id !== jobId);
        } else {
          userFavorites.push(jobId);
        }
        await updateDoc(docRef, { favorites: userFavorites });
        setUserFavorites(userFavorites);
      }
    }
  }
  function showFavoriteJobs() {
    setShowFavorites(!showFavorites);
    if (!showFavorites) {
      setFavoriteJobs(jobs.filter(job => userFavorites.includes(job.job_id)));
    }
  }

  let generateCoverLetter = async (jobDescription) => {
    try {
      setEmail("");
      setCoverLetter("");
      toast({
        title: "We are in the process of generating you a really nice cover letter. Please wait...",
        status: "info",
        position: "top",
        duration: 6000,
        isClosable: true,
      });
      setGptLoadingCover(true);

      let user = auth.currentUser;
      let firstName = user?.displayName.split(' ')[0];
      let response = await axios.post('https://api.openai.com/v1/chat/completions', {
        model: 'gpt-3.5-turbo',
        messages: [
          {
            role: 'system',
            content: `You are a helpful assistant. My name is ${firstName} and I'm looking for a job. Generate me a cover letter that I can send to the employer based on the following job description and only respond with it. Further instructions: ${gptInstructions}`,
          },
          {
            role: 'user',
            content: jobDescription,
          },
        ],
      }, {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
        }
      });

      setCoverLetter(response.data.choices[0].message.content);
    } catch (error) {
      console.log(error);
    } finally {
      setGptInstructions("");
      setGptLoadingCover(false);
      toast({
        title: "Cover letter generation Complete. Scroll down to see the results.",
        status: "success",
        position: "top",
        duration: 6000,
        isClosable: true,
      });
    }
  };


  let generateEmail = async (jobDescription) => {
    try {

      setCoverLetter("");
      setEmail("");
      toast({
        title: "We are in the process of generating you a really nice email. Please wait...",
        status: "info",
        position: "top",
        duration: 6000,
        isClosable: true,
      });
      setGptLoadingEmail(true);
      let user = auth.currentUser;
      let firstName = user?.displayName.split(' ')[0];
      let response = await axios.post('https://api.openai.com/v1/chat/completions', {
        model: 'gpt-3.5-turbo',
        messages: [
          {
            role: 'system',
            content: `You are a helpful assistant. Make sure to include my name ${firstName} and I'm looking for a job. Generate me an email that I can send the the employer based on the following job description and only respond with it. Make sure to only include the email body. Further instructions: ${gptInstructions} `,
          },
          {
            role: 'user',
            content: jobDescription,
          },
        ],
      }, {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
        }
      });

      setEmail(response.data.choices[0].message.content);
    } catch (error) {
      alert(error);
    } finally {
      setGptInstructions("");
      setGptLoadingEmail(false);
      toast({
        title: "Email generation Complete. Scroll down to see the results.",
        status: "success",
        position: "top",
        duration: 6000,
        isClosable: true,
      });
    }
  };
  
  return (

    <ChakraProvider theme={theme}>
     
     {loading ?
        <Modal isOpen={true} isCentered>
          <ModalOverlay
            css={{
              background: '#052529',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ScaleFade initialScale={0.9} in={true}>
              <Flex direction="column" align="center" justify="center" height="100vh" width="100vw">
                <Spinner size="xl" color="#81e6d9" />
                <Center mt={4}>We're almost there</Center>
              </Flex>

            </ScaleFade>
          </ModalOverlay>
        </Modal>
          :(


          <Box>
             <Box
        pos="relative"
        backgroundColor="#052529"
        bgRepeat="repeat"
        height="100%"
      >
            <Flex direction="column" minHeight="100vh">
              <Header />
              <Box flex="1">
                <Box textAlign="center" fontSize="xl">
                  <Box h="2/3" display="flex" alignItems="center" justifyContent="center">
                    <ScrollLink
                      activeClass="active"
                      to="jobCards"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      <Heading as="h1" mt={4} size="2xl" color="#FFFFFF">Search Jobs</Heading>
                    </ScrollLink>
                  </Box>
                  <Flex justify="center" mt="8">
                    <Box p="4" maxWidth="lg" boxShadow="lg" >
                      <Flex justify="space-between">
                        <Flex flexDirection="column" w="100%">
                          <Textarea
                            placeholder="Try: Marketing Jobs in London"
                            h="90px"
                            color="#81e6d9"
                            value={keyword}
                            onChange={handleKeywordChange}
                            isRequired
                            fontSize='xl'
                            onKeyDown={handleKeyDown}
                            resize="none"
                            _hover={{ borderColor: "#81e6d9" }}
                            _focus={{ borderColor: "#81e6d9" }}
                          />
                        </Flex>
                        <Button h="90px" w="170px" colorScheme="teal" ml="4" onClick={searchJobs} isDisabled={!keyword || loading}> {loading ? (
                          <CircularProgress isIndeterminate size="24px" color="teal" />
                        ) : (
                          "Search"
                        )}</Button>
                      </Flex>
                    </Box>
                  </Flex>
                  <Box mx="auto" mt="8">

                    <Box mx="auto" width="100%" paddingLeft="25px" paddingRight="25px">
                      <Flex alignItems="center" justifyContent="space-between">


                        <Box mx="auto" width="100%">

                          <Flex alignItems="center" justifyContent="space-between" flexWrap="wrap">

                            <Box mt={2} mb={1}>
                              {jobs.length > 0 && !isCollapsed && (
                                <Filters handleFiltersChange={handleFiltersChange} clearFilters={clearFilters} jobs={jobs} />

                              )}
                            </Box>
                            {isCollapsed && (
                              <Box mt={2} mb={1}>
                                <Filters handleFiltersChange={handleFiltersChange} clearFilters={clearFilters} jobs={jobs} />

                              </Box>
                            )}

                          </Flex>
                          <Flex display="flex" alignItems="flex-end" justifyContent="space-between" flexDirection="row" mb={2}>
                            <Button
                              mb={2}
                              leftIcon={showFavorites ? <FaStar /> : <FaRegStar />}
                              onClick={showFavoriteJobs}
                            >
                              {showFavorites ? "Hide Favorites" : "Show Favorites"}
                            </Button>
                            <Flex align="center">
                              <Text mb={1}>
                                {showFavorites ? `${favoriteJobs.length}` : `${filteredJobs.length}`} {filteredJobs.length === 1 ? 'Job' : 'Jobs'}
                              </Text>
                            </Flex>
                          </Flex>
                        </Box>
                      </Flex>

                    </Box>

                    {!isCollapsed && (
                      <Grid
                        id="jobCards"
                        templateColumns="repeat(auto-fill, minmax(250px, 1fr))"
                        gap={6}
                        width="100%"
                        autoFlow="dense"
                        paddingLeft="25px"
                        paddingRight="25px"
                        paddingBottom="25px"
                      >

                        {
                          jobs.length > 0 ? (
                            (showFavorites ? favoriteJobs : jobs)
                              .filter(job => {
                                if (filters.jobTitles.length > 0 && !filters.jobTitles.includes(job.job_job_title)) {
                                  return false;
                                }
                                if (filters.locations.length > 0 && !filters.locations.includes(job.job_city)) {
                                  return false;
                                }
                                if (filters.salary.length > 0) {
                                  let salaryMatch = false;
                                  for (let i = 0; i < filters.salary.length; i++) {
                                    let salaryRange = filters.salary[i].split("-");
                                    let minSalary = parseInt(salaryRange[0]);
                                    let maxSalary = parseInt(salaryRange[1]);
                                    if (job.job_min_salary >= minSalary && job.job_max_salary <= maxSalary) {
                                      salaryMatch = true;
                                      break;
                                    }
                                  }
                                  if (!salaryMatch) {
                                    return false;
                                  }
                                }
                                // This function maps human-friendly education level names to the corresponding keys in job.job_required_education
                                function mapEducationNameToKey(name) {
                                  switch (name.toLowerCase()) {
                                    case "associate's degree":
                                      return "associates_degree";
                                    case "bachelor's degree":
                                      return "bachelors_degree";
                                    // add cases for other education levels here...
                                    default:
                                      return null;
                                  }
                                }

                                let educationKeyMap = {
                                  "Associate's Degree": 'associates_degree',
                                  "Bachelor's Degree": 'bachelors_degree',
                                  "Degree Mentioned": 'degree_mentioned',
                                  "Degree Preferred": 'degree_preferred',
                                  "High School": 'high_school',
                                  "Postgraduate Degree": 'postgraduate_degree',
                                  "Professional Certification": 'professional_certification',
                                  "Professional Certification Mentioned": 'professional_certification_mentioned',
                                };

                                if (filters.education.length > 0) {
                                  let educationMatch = false;
                                  for (let i = 0; i < filters.education.length; i++) {
                                    let educationKey = educationKeyMap[filters.education[i]];
                                    if (educationKey && job.job_required_education[educationKey]) {
                                      educationMatch = true;
                                      break;
                                    }
                                  }
                                  if (!educationMatch) {
                                    return false;
                                  }
                                }


                                // If all filters pass, include the job in the filtered list
                                return true;
                              })
                              .map((job, index) => (
                                <ScaleFade key={index}  initialScale={0.9} in={true}>
                                  <Card
                                    key={job.job_id}
                                    className={"fadeInCard"}
                                    p="4"
                                    pb={6}
                                    boxShadow="lg"
                                    borderRadius="md"
                                    borderColor="#81E6D9"
                                    borderWidth="2px"
                                    bg="transparent"
                                  >
                                    <Flex align="flex-start" mb="2" direction="column">
                                      <Flex w="100%" justifyContent="space-between">
                                        <Flex mr="2">
                                          <Image
                                            src={job.employer_logo || imagePlaceholder}
                                            alt={job.employer_name || 'Employer logo'}

                                            height="45px"

                                            objectFit="fill"
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src = 'https://dummyimage.com/150x150/041d44/81E6D9'
                                            }}
                                          />

                                        </Flex>
                                        <Flex>
                                          <Button size="sm"
                                            color="#031D44"
                                            bg="#81E6D9"
                                            onClick={() => openModal(job.job_id)}
                                          >
                                            Description
                                          </Button>
                                          {selectedJobId === job.job_id && (
                                            <Modal isOpen={isModalOpen} onClose={() => {
                                              setEmail("");
                                              setCoverLetter("");
                                              setIsModalOpen(false);
                                            }}

                                            >

                                              <ModalOverlay />
                                              <ModalContent

                                              >
                                                <ModalHeader bg="#052529">
                                                  {
                                                    job.job_job_title ?
                                                      <>
                                                        <Text color="#81E6D9" display="inline">
                                                          {job.job_job_title}
                                                        </Text>
                                                        <Text ml={1} mr={1} color="white" display="inline">
                                                          |
                                                        </Text>
                                                      </>
                                                      : null
                                                  }

                                                  <Text color="red.300" display="inline">
                                                    {job.employer_name}
                                                  </Text>
                                                </ModalHeader>
                                                <ModalCloseButton />
                                                <ModalBody overflowY="auto" maxH="70vh" bg="#052529" pb={4} pt={4}>
                                                  <Text fontSize="sm" color="white">
                                                    <JobDescription description={job.job_description} />
                                                  </Text>
                                                  <Input
                                                    w="100%"
                                                    placeholder='Optional instructions for the AI'
                                                    mb={4}
                                                    isDisabled={gptLoadingEmail || gptLoadingCover ? true : false}
                                                    value={gptInstructions}
                                                    onChange={(e) => setGptInstructions(e.target.value)}
                                                  />
                                                  <Flex>


                                                    <Button
                                                      w="50%"
                                                      size="sm"
                                                      color="#031D44"
                                                      bg="#81E6D9"
                                                      rightIcon={<FaRobot />}
                                                      onClick={() => generateCoverLetter(job.job_description)}
                                                      mr={4}
                                                      isDisabled={gptLoadingCover || false}
                                                      leftIcon={
                                                        gptLoadingCover ? (
                                                          <Spinner size="xs" />
                                                        ) : null
                                                      }
                                                    >
                                                      Cover Letter
                                                    </Button>
                                                    <Button
                                                      w="50%"
                                                      size="sm"
                                                      color="#031D44"
                                                      bg="#81E6D9"
                                                      rightIcon={<FaRobot />}
                                                      onClick={() => generateEmail(job.job_description)}
                                                      isDisabled={gptLoadingEmail || false}
                                                      leftIcon={
                                                        gptLoadingEmail ? (
                                                          <Spinner size="xs" />
                                                        ) : null
                                                      }
                                                    >
                                                      Email
                                                    </Button>
                                                  </Flex>

                                                  {coverLetter && (
                                                    <Card mt={4} variant='outline'>
                                                      <CardHeader>
                                                        <Heading size='md' color="#81e6d9">Cover Letter</Heading>
                                                      </CardHeader>
                                                      <CardBody>
                                                        <Text>{coverLetter}</Text>
                                                      </CardBody>
                                                    </Card>
                                                  )}

                                                  {email && (
                                                    <Card mt={4} variant='outline'>
                                                      <CardHeader>
                                                        <Heading size='md' color="#81e6d9">Email</Heading>
                                                      </CardHeader>
                                                      <CardBody>
                                                        <Text>{email}</Text>
                                                      </CardBody>
                                                    </Card>
                                                  )}
                                                </ModalBody>
                                              </ModalContent>

                                            </Modal>
                                          )}
                                        </Flex>
                                      </Flex>
                                      {job.job_job_title && (
                                        <Text fontSize="md" pt={2} pb={1} fontWeight="bold" color="white">
                                          {truncateText(job.job_job_title, 30)}
                                        </Text>
                                      )}
                                    </Flex>

                                    <Flex align="center" mb="2">
                                      <Box as={FaBuilding} size="15px" color="#81E6D9" />
                                      <Text ml="2" fontSize="sm" color="white">
                                        {truncateText(job.employer_name, 26) || 'Employer Name Not Available'}
                                      </Text>
                                    </Flex>

                                    <Flex align="center" mb="2">
                                      <Box as={FaChartBar} size="15px" color="#81E6D9" />
                                      <Text ml="2" fontSize="sm" color="white">
                                        {job.employer_company_type ? job.employer_company_type : 'No data'}
                                      </Text>
                                    </Flex>

                                    <Flex align="center" mb="2">
                                      <Box as={FaLink} size="15px" color="#81E6D9" />
                                      <Text ml="2" fontSize="sm" color="white">
                                        {truncateText(job.employer_website, 30) || 'No Data'}
                                      </Text>
                                    </Flex>

                                    <Flex align="center" mb="2">
                                      <Box as={FaMapMarkerAlt} size="15px" color="#81E6D9" />
                                      <Text ml="2" fontSize="sm" color="white">
                                        {truncateText(`${job.job_city}, ${job.job_country}`, 30)}
                                      </Text>
                                    </Flex>
                                    <Flex align="center" mb="2">
                                      <Box as={FaStarHalfAlt} size="15px" color="#81E6D9" />
                                      <QualityStars score={job.job_apply_quality_score} />
                                    </Flex>
                                    <Flex align="center" mb="2">
                                      <Box as={FaUserTie} size="15px" color="#81E6D9" />
                                      <Text ml="2" fontSize="sm" color="white">
                                        {job.job_employment_type ? toTitleCase(job.job_employment_type) + ' job' : 'Employment type not specified'}
                                      </Text>
                                    </Flex>

                                    <Flex align="center" mb="2">
                                      <Box as={FaGraduationCap} size="15px" color="#81E6D9" />
                                      <Text ml="2" fontSize="sm" color="white" textAlign="left">
                                        {
                                          job.job_required_education.associates_degree ? 'Associates Degree' :
                                            job.job_required_education.bachelors_degree ? 'Bachelors Degree' :
                                              job.job_required_education.degree_mentioned ? 'Degree Mentioned' :
                                                job.job_required_education.degree_preferred ? 'Degree Preferred' :
                                                  job.job_required_education.high_school ? 'High School' :
                                                    job.job_required_education.postgraduate_degree ? 'Postgraduate Degree' :
                                                      job.job_required_education.professional_certification ? 'Professional Certification' :
                                                        job.job_required_education.professional_certification_mentioned ? 'Professional Certification Mentioned' :
                                                          'No data'
                                        }
                                      </Text>
                                    </Flex>

                                    <Flex align="center" mb="2">
                                      <Box as={FaCoins} size="15px" color="#81E6D9" />
                                      {(job.job_min_salary !== null && job.job_min_salary !== 0 && job.job_max_salary !== null && job.job_max_salary !== 0) ? (
                                        <Text ml="2" fontSize="sm" color="white">
                                          {getCurrencyFormat(job.job_salary_currency).placeAfter
                                            ? `${job.job_max_salary}${getCurrencyFormat(job.job_salary_currency).symbol}`
                                            : `${getCurrencyFormat(job.job_salary_currency).symbol}${job.job_max_salary}`
                                          } per {job.job_salary_period.toLowerCase()}
                                        </Text>
                                      ) : (
                                        <Text ml="2" fontSize="sm" color="white">
                                          No data
                                        </Text>
                                      )}
                                    </Flex>

                                    <Flex align="center" mb="2">
                                      <Box as={FaRegCheckCircle} size="15px" color="#81E6D9" />
                                      <Text ml="2" fontSize="sm" color="white">
                                        {job.job_apply_is_direct ? 'Direct apply is available' : 'Direct apply is not available'}
                                      </Text>
                                    </Flex>

                                    <Flex align="center" mb="2">
                                      <Box as={FaClock} size="15px" color="#81E6D9" />

                                      <Text ml="2" fontSize="sm" color="white">
                                        {formatDate(job.job_posted_at_datetime_utc) || 'Job Posting Date Not Available'}
                                      </Text>
                                    </Flex>
                                    <Stack spacing={2} mt={4}>
                                      <Button
                                        size="sm"
                                        color="white"
                                        rightIcon={<FaEnvelope />}
                                        onClick={() => sendEmailToUser(job.job_apply_link, job)}
                                      >
                                        Email it to me
                                      </Button>
                                      <Button
                                        size="sm"
                                        color="white"
                                        rightIcon={userFavorites.includes(job.job_id) ? <FaStar /> : <FaRegStar />}
                                        onClick={() => handleFavoriteClick(job.job_id)}
                                      >
                                        {userFavorites.includes(job.job_id) ? "Remove from Favorites" : "Add to Favorites"}
                                      </Button>
                                      <Button
                                        size="sm"
                                        color="#031D44"
                                        bg="#81E6D9"
                                        rightIcon={<FaExternalLinkAlt />}
                                        onClick={() => window.open(job.job_apply_link, "_blank")}
                                      >
                                        Apply Now
                                      </Button>

                                    </Stack>
                                  </Card>
                                </ScaleFade>
                              ))

                          ) : (
                            null

                          )}
                      </Grid>
                    )}

                  </Box>
                </Box>
                {showScroll &&
                  <IconButton
                    colorScheme="teal"
                    icon={<ArrowUpIcon />}
                    isRound="true"
                    size="lg"
                    onClick={scrollToTop}
                    position="fixed"
                    right="30px"
                    bottom="30px"
                  >
                    Scroll up
                  </IconButton>
                }
                </Box>
            </Flex>
            <Footer />
          
          </Box>
          </Box>
    )}
 
</ChakraProvider>
  );

}


export default Jobs;
