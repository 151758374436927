import React from 'react';
import {
  Box,
  Button,
  Flex,
  Container,
  Heading,
  Stack,
  Text,
  Icon,
  Image,
  useColorModeValue,
  Avatar,
} from '@chakra-ui/react';
import { HashLink as Links } from 'react-router-hash-link';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FaRocket, FaLightbulb, FaUserCheck } from 'react-icons/fa';
import { Footer } from "./Footer";
import job from '../assets/img/job4.png';

const Testimonial = ({ children }) => {
  return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }) => {
  return (
    <Stack
      borderColor="#81e6d9"
      borderWidth={"2px"}
      border="solid"
      boxShadow={'lg'}
      p={8}

      rounded={'xl'}
      align={'center'}
      pos={'relative'}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: 'solid transparent',
        borderLeftWidth: 16,
        borderRight: 'solid transparent',
        borderRightWidth: 16,
        borderTop: 'solid',
        borderTopWidth: 16,
        borderTopColor: useColorModeValue('white', 'gray.800'),
        pos: 'absolute',
        bottom: '-16px',
        left: '50%',
        transform: 'translateX(-50%)',
      }}>
      {children}
    </Stack>
  );
};

const TestimonialHeading = ({ children }) => {
  return (
    <Heading as={'h3'} fontSize={'xl'}>
      {children}
    </Heading>
  );
};

const TestimonialText = ({ children }) => {
  return (
    <Text
      textAlign={'center'}
      color={'white'}
      fontSize={'sm'}>
      {children}
    </Text>
  );
};

const TestimonialAvatar = ({ src, name, title }) => {
  return (
    <Flex align={'center'} mt={8} direction={'column'}>
      <Avatar src={src} alt={name} mb={2} />
      <Stack spacing={-1} align={'center'}>
        <Text color="#FFFFFF" fontWeight={600}>{name}</Text>
        <Text fontSize={'sm'} color="#81e6d9">
          {title}
        </Text>
      </Stack>
    </Flex>
  );
};

export function WithSpeechBubbles() {
  return (
    <Box >
      <Container maxW={'7xl'} py={16} as={Stack} spacing={12}>
        <Stack spacing={0} align={'center'}>
          <Heading color="white">What Our Clients Say</Heading>
          <Text color="white">We are privileged to work with these amazing clients</Text>
        </Stack>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 10, md: 4, lg: 10, sm: 1 }}
          flexWarp={'wrap'}
        >
          <Testimonial  >
            <TestimonialContent  >
              <TestimonialHeading>Efficient Collaboration</TestimonialHeading>
              <TestimonialText color={'white'}>
                Working with the team was a breeze. Their attention to detail and proactive approach to problem-solving made our collaboration highly efficient.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={
                'https://images.unsplash.com/photo-1493752603190-08d8b5d1781d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80'
              }
              name={'Michael Smith'}
              title={'CEO at TechInnovate'}
            />

          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Innovative Design</TestimonialHeading>
              <TestimonialText color={'white'}>

                The designs provided were innovative and intuitive. They seamlessly integrated with our existing systems, improving overall user experience significantly.

              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={
                'https://images.unsplash.com/photo-1544348817-5f2cf14b88c8?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80'
              }
              name={'Sam Johnson'}
              title={'CEO at ABC Corporation'}
            />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Exceptional Service</TestimonialHeading>
              <TestimonialText color={'white'}>
                The level of customer service we received was beyond our expectations. The team was always available and ready to address our needs, truly mind-blowing service!
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={
                'https://images.unsplash.com/photo-1557862921-37829c790f19?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80'
              }
              name={'Tom Lee'}
              title={'CTO at Gamma Tech'}
            />
          </Testimonial>
        </Stack>
      </Container>
    </Box>
  );
}
const scrollToFeatures = () => {
  const featuresElement = document.getElementById('Features');
  if (featuresElement) {
    window.scrollTo({
      top: featuresElement.offsetTop,
      behavior: 'smooth'
    });
  }
};

function Homepage(props) {
  const { user } = props;
  return (
    <Box
      sx={{
        backgroundImage: `url('${job}')`,
        backgroundPosition: 'center',
        _important: { backgroundImage: true, backgroundPosition: true },
      }}
      bgSize="cover"
      bgRepeat="no-repeat"
      alt='job interview'
      width={'100%'}
      minHeight={'100vh'}
    >
      {/* Hero Section */}
      <Stack
        textAlign={'center'}
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        height={'100vh'}
        justifyContent={'center'}
      >
        <Flex flexDirection="column" justifyContent="center" height="100vh">
          <Flex w={"100%"} justifyContent="center">
            <Heading
              fontWeight={600}
              fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
              lineHeight={'110%'}
              color="white"
            >
              Job search{' '}
              <Text as={'span'} color="#81e6d9" onClick={scrollToFeatures}>
                Made Easy
              </Text>
            </Heading>
          </Flex>
          <Flex mt={6} >
            <Text color={'white'} maxW={'3xl'} pl={4} pr={4}>
              The ultimate app designed to revolutionize your job search experience! In today's competitive world, we understand how challenging it can be to navigate the job market and land that dream position.
            </Text>
          </Flex>
          <Flex alignItems={"center"} justifyContent="center" mt={6}>
            <Stack spacing={6} direction={'row'}>
              <Button
                rounded={'full'}
                px={6}
                colorScheme={'teal'}
                bg={'teal.400'}
                _hover={{ bg: 'teal.500' }}
                as={Link}
                to={user ? '/jobs' : '/login'}
              >
                {user ? 'Go to Jobs' : 'Login to see Jobs'}
              </Button>

              <Button rounded={'full'} px={6} as={Links} smooth to='#Features'>
                Learn more
              </Button>

            </Stack>
          </Flex>
        </Flex>
      </Stack>
      <Container maxW={'7xl'} id='Features' pt={4}>
        <Stack spacing={{ base: 4, md: 2 }} direction={{ base: 'column', md: 'row' }}>
          <Box p={5} shadow="md" borderWidth="1px" flex="1" borderRadius="md">
            <Icon as={FaRocket} boxSize={12} color="#81e6d9" />
            <Heading color={'white'} fontSize="xl">Accelerate your Search</Heading>
            <Text color={'white'} mt={4}>Our platform helps you speed up your job search and find opportunities faster than ever.</Text>
          </Box>
          <Box p={5} shadow="md" borderWidth="1px" flex="1" borderRadius="md">
            <Icon as={FaLightbulb} boxSize={12} color="#81e6d9" />
            <Heading color={'white'} fontSize="xl">Smart Recommendations</Heading>
            <Text color={'white'} mt={4}>Our intelligent algorithms provide job recommendations that match your skills and preferences.</Text>
          </Box>
          <Box p={5} shadow="md" borderWidth="1px" flex="1" borderRadius="md">
            <Icon as={FaUserCheck} boxSize={12} color="#81e6d9" />
            <Heading color={'white'} fontSize="xl">Verified Employers</Heading>
            <Text color={'white'} mt={4}>We verify all employers on our platform to ensure you only interact with credible entities.</Text>
          </Box>
        </Stack>
      </Container>

      {/* Testimonials Section */}
      <Container maxW={'7xl'} pt={4}>
        <WithSpeechBubbles />
      </Container>

      <Footer />
    </Box>
  );
}



export default Homepage;
