import { Box, Text } from "@chakra-ui/react";
import { format } from "date-fns";

let CurrentYear = format(new Date(), 'yyyy');

export let Footer = () => (
  <Box bg="transparent" p={4} color="white" bottom="0" width="100%" textAlign="center" pb={5}>
    <Text>© {CurrentYear} Job Hunt UK 2024. All rights reserved.</Text>
  </Box>
);
